<template>
  <q-dialog
    ref="dialogRef"
    position="bottom"
    square
    @hide="onDialogHide"
  >
    <q-card class="q-dialog-plugin">
      <q-card-section>
        <div class="text-body1 text-weight-light text-center q-pa-sm">
          Please enter your new password.
        </div>
        <q-form @submit="onResetSubmit()">
          <div class="q-gutter-y-md column">
            <!-- eslint-disable @stylistic/max-len -->
            <q-input
              v-model="password"
              label="Password"
              type="password"
              :disable="pending"
              :rules="[
                (val) => !!val || 'Password is required.',
                (val) =>
                  val.length >= 8
                  || 'Passwords must be at least 8 characters long.',
                (val) =>
                  /(?=.*[A-Z])/.test(val)
                  || 'Passwords must have at least one uppercase (\'A\'-\'Z\').',
                (val) =>
                  /(?=.*[a-z])/.test(val)
                  || 'Passwords must have at least one lowercase (\'a\'-\'z\').',
                (val) =>
                  /(?=.*\d)/.test(val)
                  || 'Passwords must have at least one digit (\'0\'-\'9\').',
                (val) =>
                  /([^a-zA-Z0-9])/.test(val)
                  || 'Passwords must have at least one non alphanumeric character like (\'!\', \'@\', \'#\', \'$\', \'%\', \'$\').',
              ]"
            />
            <!-- eslint-enable @stylistic/max-len -->
            <q-input
              v-model="confirmPassword"
              label="Confirm Password"
              type="password"
              :disable="pending"
              :rules="[
                (val) => !!val || 'Confirm Password is required.',
                (val) => val == password || 'Passwords do not match.',
              ]"
            />
            <q-btn
              :loading="pending"
              color="primary"
              type="submit"
              label="Reset"
              square
            />
          </div>
        </q-form>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang="ts" setup>
import { useDialogPluginComponent } from 'quasar';

const props = defineProps({
  email: {
    type: String,
    required: true,
  },
  token: {
    type: String,
    required: true,
  },
});

defineEmits([...useDialogPluginComponent.emits]);

const { dialogRef, onDialogHide, onDialogOK } = useDialogPluginComponent();
const authStore = useAuthStore();
const $q = useQuasar();
const { $ecommerce } = useNuxtApp();

const password = ref<string | null>(null);
const confirmPassword = ref<string | null>(null);
const pending = ref(false);

const onResetSubmit = async () => {
  pending.value = true;

  try {
    await $ecommerce.fetch('auth/password', {
      method: 'PUT',
      body: {
        email: props.email,
        token: props.token,
        password: password.value,
        confirmPassword: confirmPassword.value,
      },
    });

    $q.notify({
      type: 'positive',
      message: 'Password successfully updated.',
    });

    if (!authStore.loggedIn) {
      await authStore.login({
        username: props.email,
        password: password.value,
      });
    }

    await navigateTo('/account');

    onDialogOK();
  }
  catch (error) {
    $ecommerce.handle(error);
  }

  pending.value = false;
};
</script>
